import { metalPriceToSlug, metalToSlug, SANITY_PAGE_ROUTE } from '@core/constant';
import { ELanguage, EMetal, ERouting } from '@core/type';
import { BreadcrumbEntity } from '@core/type/api';
import { SanityBreadcrumb } from '@core/type/sanity';
import { getLanguageTags, getTypeRoute } from '@core/util';

export const getSanityPageRoute = (
  keyRoute: ERouting,
  language: ELanguage,
  slug: string,
  metalIso: EMetal,
) => {
  switch (keyRoute) {
    case ERouting.HOME: {
      return `/${language}`;
    }
    case ERouting.PAGES:
      return `/${language}/${keyRoute}/${slug}`;
    case ERouting.CHARTS:
      return `/${language}/${
        metalPriceToSlug[getLanguageTags(language)][metalIso as EMetal]
      }/${slug}`;
    case ERouting.SHOP_SUBCATEGORY:
      return `/${language}/${SANITY_PAGE_ROUTE[getLanguageTags(language)][ERouting.HOME_BUY]}/${
        metalToSlug[getLanguageTags(language)][metalIso as EMetal]
      }/${slug}`;
    case ERouting.BUY:
      return `/${language}/${
        SANITY_PAGE_ROUTE[getLanguageTags(language)][ERouting.HOME_BUY]
      }/${slug}`;
    case ERouting.HOME_BUY:
      return `/${language}/${SANITY_PAGE_ROUTE[getLanguageTags(language)][ERouting.HOME_BUY]}`;
    default:
      return `/${language}/${slug}`;
  }
};

export const mapSanityBreadcrumb = (
  breadcrumbs: SanityBreadcrumb[],
  currentKeyRoute: ERouting,
): BreadcrumbEntity[] =>
  breadcrumbs?.map(({ text, internalLink, keyRoute, language, slug, metalIso, href }, index) => ({
    title: text,
    slug: internalLink?._ref ? getSanityPageRoute(keyRoute, language, slug, metalIso) : href,
    breadcrumbType: getTypeRoute(currentKeyRoute)?.keyRouteDynamic,
    index,
  }));
