import { FC, forwardRef, useMemo } from 'react';

import { EActionAttribute, EColor } from '@core/type';

import { InputBorder } from '../input';
import { InputHidden } from '../input/InputHidden';
import { Box } from '../layout/Box';
import { ButtonRadioCircleProps, ButtonRadioProps } from './interface-button';

const Circle: FC<ButtonRadioCircleProps> = ({ isActive, isDisabled, ...restProps }) => (
  <Box
    pointerEvents={'none'}
    display={'flex'}
    flex-shrink={0}
    width={20}
    height={20}
    borderRadius={'50%'}
    position={'relative'}
    borderColor={isDisabled ? EColor.NEUTRAL : isActive ? EColor.SECONDARY : EColor.PRIMARY}
    borderWidth={isActive ? '6px' : '1px'}
    transition={'0.2s'}
    {...restProps}
  />
);

export const ButtonRadio = forwardRef<HTMLInputElement, ButtonRadioProps>(
  (
    {
      children,
      label: labelProps,
      value,
      currentValue,
      field,
      wrapperProps,
      circleProps,
      labelWrapperProps,
      disabled,
      marginBottom,
      withBorder = false,
      inputBorderProps,
      ...inputProps
    },
    ref,
  ) => {
    const label = useMemo(() => children || labelProps || null, [children, labelProps]);
    const isSelected = value === currentValue;

    return (
      <Box
        as={EActionAttribute.LABEL}
        display={'flex'}
        alignItems={'center'}
        flexShrink={0}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        position={'relative'}
        {...wrapperProps}
        aria-label={value}
        aria-selected={isSelected}
        marginBottom={marginBottom}
      >
        {withBorder && <InputBorder {...inputBorderProps} />}
        <InputHidden
          {...inputProps}
          disabled={disabled}
          value={value}
          ref={field?.ref || ref}
          type={'radio'}
          checked={isSelected}
        />
        <Circle isActive={isSelected} isDisabled={disabled} {...circleProps} />
        {label && (
          <Box
            position={withBorder ? 'relative' : undefined}
            paddingLeft={16}
            color={disabled ? EColor.NEUTRAL : EColor.PRIMARY}
            {...labelWrapperProps}
          >
            {label}
          </Box>
        )}
      </Box>
    );
  },
);

ButtonRadio.displayName = 'ButtonRadio';
